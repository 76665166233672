import React from "react";
import { useHistory } from "react-router";

import { PrimeIcons } from "primereact/api";

import { tawreedTranslationService } from "../../../../../common/translations";
import { Routes } from "../../../../../router";
import { fcm, messagesService, toastService } from "../../../domain";
import { GlobalContext } from "../../../../../context";

declare type NotificationsTopbarButtonProps = {

    className: string;

    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const NotificationsTopbarButton: React.FC<NotificationsTopbarButtonProps> = (props) => {

    const history = useHistory();
    const [value, setValue] = React.useState<number>(0);
    const [text, setText] = React.useState<string>('');
    const { auth }= React.useContext(GlobalContext);

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event) {
            history.push(Routes.Notifications);
            if (props.onClick) {
                props.onClick(event);
            }
        }
    }


    React.useEffect(
        () => {
            if(auth.user && auth.user.userId){
            const unsubscribe1 = fcm.onMessageListener(res => {
                if (res.data) {
                    // typeCode, messageId, recordId, click_action
                    const { messagesCount, title, body } = res.data;

                    toastService.show({ severity: "info", summary: title, detail: body });
                    messagesService.setCount(Number.parseInt(messagesCount));
                }
            });
            const unsubscribe2 = messagesService.getCount(res => setValue(res));

            return () => {
                unsubscribe1();
                unsubscribe2();
            }
        }
        },
        [history]);

    React.useEffect(
        () => {
            let mounted = true;
            let text = '';

            if (mounted) {
                if (!value) {
                    text = '';
                } else if (value < 10) {
                    text = value.toLocaleString();
                } else {
                    text = '+10';
                }
                setText(text);
            }
            return () => { mounted = false; };
        },
        [value])

    return (
        <React.Fragment>
            <button className={props.className} onClick={onClick} title={tawreedTranslationService.translate('lbl_notifications')}>
                <i className={PrimeIcons.BELL} />
                <span className="p-button-label p-c">{tawreedTranslationService.translate('lbl_notifications')}</span>
                {
                    text &&
                    text.length &&
                    <span className=" flex justify-content-center p-badge p-badge-danger">{text}</span>
                }
            </button>
        </React.Fragment>
    );
}
