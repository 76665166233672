import React from "react";
import {tawreedTranslationService} from "../../../../../common/translations";
import {Panel} from "primereact/panel";
import {CssUtils} from "../../../../../helpers/css";
import {ObjectUtils} from "../../../../../helpers/object";

export type PublicOrderFormSummaryProps = {
    // controls
    className?: string;
    subTotal?: number;
    shippingTotal?: number;
    discount?: number;
    total?: number;
    totalTax?:number;
    grossTotal?: number;

};

export const PublicOrderFormSummary: React.FC<PublicOrderFormSummaryProps> = (props) => {

    return (
        <Panel header={tawreedTranslationService.translate('lbl_sales_orders_summary')} className={props.className}>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <div className="grid">
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_gross_total_unit')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.grossTotal)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_total_discount_unit')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.discount)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_sub_total_unit')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.subTotal)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_total_tax_unit')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.totalTax)}
                        </div>
                            <React.Fragment>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_sales_orders_shipping_total_unit')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {ObjectUtils.toFixed(props.shippingTotal)}
                                </div>
                            </React.Fragment>

                        <hr style={{width: '100%'}}/>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_total_unit')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.total)}
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    );
}
