import {ToastMessage} from "primereact/toast";
import React from "react";
import {tawreedTranslationService} from "../../../../common/translations";

declare type ToastServiceCallback = (e: ToastMessage) => void;

class ToastService {

    private static readonly ToastTimeout = 10000; // 10 seconds

    private static getToastSummary(e: ToastMessage): React.ReactNode {
        if (e.summary) {
            return e.summary;
        } else if (e.severity) {
            return tawreedTranslationService.translate('lbl_notification_summary_' + e.severity);
        } else {
            return tawreedTranslationService.translate('lbl_notification_summary');
        }
    }

    private toast: ToastServiceCallback | undefined = undefined;

    public show(e: ToastMessage): void {
        this.onMessage(e);
    }

    private onMessage(e: ToastMessage): void {
        if (this.toast) {
            this.toast({...e, summary: ToastService.getToastSummary(e), life: ToastService.ToastTimeout});
        }
    }

    public setToast(toast: ToastServiceCallback): void {
        this.toast = toast;
    }
}

export const toastService = new ToastService();
